define("discourse/plugins/discourse-jeed/initializers/jeed", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library", "discourse/plugins/discourse-jeed/lib/jeed"], function (_exports, _pluginApi, _iconLibrary, _jeed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const runButton = '<button class="jeed play widget-button btn btn-default no-text btn-icon" style="position: relative; float: right; top: -32px; margin-bottom: -32px;">' + (0, _iconLibrary.iconHTML)('play-circle') + '</button>';
  const closeButton = '<button class="jeed play widget-button btn btn-small no-text btn-icon" style="position: absolute; right: 2px; top: 2px;">' + (0, _iconLibrary.iconHTML)('close') + '</button>';
  var _default = _exports.default = {
    name: "apply-jeed",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        (0, _jeed.default)($);
        api.decorateCooked($elem => {
          const siteSettings = api.container.lookup("site-settings:main");
          const server = siteSettings.jeed_backend;
          if (server === "") {
            console.warn("Jeed Backend site settings must be set!");
            return;
          }
          const checkstyle = siteSettings.jeed_checkstyle;
          $("pre", $elem).jeed(server, {
            runButton,
            closeButton,
            checkstyle
          });
        }, {
          id: "discourse-jeed"
        });
      });
    }
  };
});